export const Home_name = "home";
export const Home_router = "/";

export const First_car_name = "FirstCar";
export const First_car_router = "/First-car";

export const Second_car_name = "SecondCar";
export const Second_car_router = "/Second-car";

export const Third_car_name = "ThirdCar";
export const Third_car_router = "/Third-car";

export const Fourth_car_name = "FourthCar";
export const Fourth_car_router = "/Fourth-car";
